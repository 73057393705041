<template>
    <v-container fill-height>
        <flash-item :notification="notification" :notification_message="notification_message"></flash-item>
        <v-layout justify-center wrap>
            <v-flex md12>
                <material-card :title="title">

                    <v-form
                            ref="form"
                            v-model="organisation_form"
                            lazy-validation="">

                        <v-select
                              v-model="organisation.category"
                              :items="items"
                              label="Item"
                              required=""></v-select>

                        <v-text-field
                                v-model="organisation.name"
                                label="Company name"
                                required
                        ></v-text-field>
                        <v-text-field
                                v-model="organisation.address"
                                label="Address"
                                required
                        ></v-text-field>
                        <v-text-field
                                v-model="organisation.region"
                                label="Region"
                                required
                        ></v-text-field>

                        <v-text-field
                                v-model="organisation.zip_code"
                                label="Zip Code"
                                required
                        ></v-text-field>

                        <v-text-field
                                v-model="organisation.country"
                                label="Country"
                                required
                        ></v-text-field>

                        <v-text-field
                                v-model="organisation.email"
                                label="Email"
                                required
                        ></v-text-field>

                        <v-text-field
                                v-model="organisation.phone"
                                label="phone"
                                required
                        ></v-text-field>

                        <v-text-field
                                v-model="organisation.website"
                                label="website"
                                required
                        ></v-text-field>


                        <v-text-field
                                v-model="organisation.fax"
                                label="fax"
                                required
                        ></v-text-field>

                        <v-text-field
                                v-model="organisation.invoice_code"
                                label="Invoice Short Code"
                                required
                        ></v-text-field>

                        <v-btn
                                color="success"
                                @click="save_organisation(organisation)"
                        >
                            Save Customer
                        </v-btn>
                    </v-form>

                </material-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import FlashItem from '../../../components/helper/FlashItem.vue'

    export default {
        components: {
            FlashItem
        },
        data() {
            return {
                notification: false,
                notification_message: '',
                title: '',
                organisation: {'id': 0},
                user: {},
                organisation_form:true
            }
        },
        created() {
            this.organisation_id = parseInt(this.$route.params.id);
            this.user = this.$store.getters.user;
            if (this.organisation_id > 0) {
                console.log("GET CUSTOMER");
                this.get_organisation(this.organisation_id)
            } else {
                this.title = "New Customer";
            }
            this.organisation.user = this.user.id;
        },
        methods: {
            save_organisation(organisation) {
                if (organisation.id === 0) {
                    this.new_organisation(organisation);
                } else {
                    this.update_organisation(organisation);
                }
            },
            get_organisation(id) {
                this.$http.get(
                    this.$http.defaults.baseURL + '/admins/organisations/' + id + '/').then((response) => {
                    console.log(response);
                    this.organisation = response.data;
                    this.title = 'Editing ' + this.organisation.company_name
                })
                    .catch((error) => {
                        this.notification = true;
                        this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                    });
            },
            update_organisation(organisation) {
                this.$http.patch(
                    this.$http.defaults.baseURL + '/admins/organisations/' + this.organisation_id, organisation).then((response) => {
                    console.log(response);
                    this.notification = true;
                    this.notification_message = 'Successfully Updated Customer';

                })
                    .catch((error) => {
                        this.notification = true;
                        this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                    });
            },
            new_organisation(organisation) {
                console.log(organisation);
                console.log("New Customer");
                this.$http.post(
                    this.$http.defaults.baseURL + '/admins/organisations', organisation).then((response) => {
                    console.log(response);
                    this.notification = true;
                    this.notification_message = 'Successfully Added New Customer';
                    this.organisation = response.data.organisation;
                    this.$router.push('/c-panel/organisation/' + this.organisation.id);
                })
                    .catch((error) => {
                        this.notification = true;
                        this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                    });
            }
        }
    }
</script>
